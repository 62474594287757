import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, CircularProgress, Divider, Input, InputAdornment, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import eezib from "../images/eezib.png";
import redeem from "../images/redeem.webp";
import coins from "../images/coin.gif";
import coinImage from "../images/coinImage.png";
import eeLogo from "../images/ee.png";
import SnackBarSuccess from './SnackBarSuccess';
import Cookies from 'js-cookie';
import { HandleApi } from '../HandleApi';


const RedeemPoints = ({openRedeem, handleRedeemClose, handleForm, redeemCoins, pointValue }) => {

    console.log("redemed data : ",{openRedeem, handleRedeemClose, handleForm });

    // const [ redeemCoins , setRedeemCoins ] = React.useState("");
    const [ redemAmount , setRedemAmount ] = React.useState("");
    const [ redemProg , setRedemProg ] = React.useState(false);

    const [ message , setMessage ] = React.useState("");
    const [ status , setStatus ] = React.useState("");
    const [ open , setOpen ] = React.useState(false);


    const handleRedemAmount = (e) => {
        setRedemAmount(e.target.value)
    }

    const redemCoins  = async() => {
        try{
            // setRedemProg(true)

            if(parseFloat(redeemCoins) < parseFloat(redemAmount)){
                setOpen(true);
                    setMessage("Not Enough Points to Avail Redemption.");
                    setStatus("error");
                return;
            }

            if(Number(redemAmount?.includes("."))){
                setOpen(true);
                    setMessage("Amount Cannot Be In Decimals.");
                    setStatus("error");
                return;
            }

            const minCoinRedem = 10;

            if(Number(redemAmount) < minCoinRedem){
                setOpen(true);
                setMessage(`Minimum Redeem Criteria Consists ${minCoinRedem} Coins.`);
                setStatus("error")
                return;
            }
    
            const url = process.env.REACT_APP_UAPI_URL;
            const apiUrl = `${url}/api/redeem_point`;
            const method = "POST";
            const authAccess = Cookies.get("demoCAuth");
            const bodyData = {
                "reward_points": parseInt(redemAmount)
            }
    
            const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, 'bodyData':bodyData });
            
            setRedemProg(false)
    
            if(json){
                if(json.status === 'success'){
                    setOpen(true);
                    setMessage(json.message);
                    setStatus("success")
                    setRedemAmount("");
                    handleForm();
                    // checkBalance()
                    setTimeout(() => {
                        handleRedeemClose();
                    },2000)
                }
                if(json.status === "error"){
                    setOpen(true);
                    setMessage(json.message);
                    setStatus("error");
                    setRedemAmount("")
                }
            }    

        }catch(err){

        } }

        // const handleRedeemPoints = async() => {
        //     try{
        //         const url = process.env.REACT_APP_UAPI_URL;
        //         const apiUrl = `${url}/api/b2c_reward_wallet `;
        //         const method = "GET";
        //         const authAccess = Cookies.get("demoCAuth");
            
        //         const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess });
            
        //         if(json){
        //             if(json.status === "success"){
        //                 setRedeemCoins(json?.data?.rewardcoins);
        //                 // setOpenRedeem(true);
        //             }
        //         }
        //     }catch(err){
        //         console.log(err);
        //     }
        // }

        const handleRedeemOption = () => {
            handleRedeemClose();
            setRedemAmount("")
        }


  return (
    <div>
         <Dialog
        open={openRedeem}
        onClose={handleRedeemClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseIcon onClick={handleRedeemOption} sx={{ position:'absolute', right:0, fontSize:"1.2rem", color:"red", cursor:"pointer" }} />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
            <Box >
                <Box component='img' src={eezib} sx={{ width:"7rem" }} />
            </Box>
                <Divider sx={{ width:"100%", mt:2 }} />
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" } }} >
            <Box component="img" src={redeem} sx={{ width:"15rem" }} />
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:'0.5rem', width:"100%" }} >
                <Typography sx={{ fontFamily:'poppins', fontWeight:500, fontSize:"1.1rem", color:'#757575' }} >Current <Box component="img" src={eeLogo} sx={{ width:"1.3rem"}} /> Points..</Typography>
                <Typography  sx={{ fontFamily:'poppins', fontWeight:400, fontSize:"1.2rem", textAlign:'center', backgroundColor:"#5385e0", padding:"0.5rem" ,color:"white", borderRadius:"2rem", width:"12rem" }} >{redeemCoins}</Typography>


                <Box sx={{ width:"12rem", mt:'1rem' }} >
                    <label style={{ fontFamily:'poppins', fontSize:"0.8rem", color:'gray' }} >Enter Redemption Points

                    </label>
                    <Input type='number' value={redemAmount} onChange={handleRedemAmount} startAdornment={
                        <InputAdornment position='start'>
                            <Box src={coins} alt="coin" component="img" sx={{ width:"1.5em", borderRadius:50 }} />
                            {/* <CancelOutlined/> */}
                        </InputAdornment>
                    } size="small"   />
                    {
                     redemAmount < 10 ?  
                     <label style={{ fontSize:"0.6rem", fontWeight:500, color:"gray" }} >Min Redemption - 10 points</label> : null
                    }
                </Box>
                {
                    redemProg ? <CircularProgress /> : 
                <Button variant='contained' disabled={redemAmount < 10 } onClick={() => redemCoins()} fullWidth sx={{ fontFamily:'Poppins', borderRadius:'12rem', mt:2 }} >Submit</Button>
                }
            </Box>
            </Box>
            <Divider sx={{ width:"100%", borderStyle:"dashed" }} />
            <label style={{ textalign:'center', fontFamily:'poppins', fontWeight:400, fontSize:"0.8rem", marginTop:"0.5rem", marginBottom:"-0.5rem", color:"darkslategray" }} > Note - {pointValue} points redeems 1 eezib coin 😊 </label>
        </DialogContent>
      </Dialog>

      <SnackBarSuccess message={message} open={open} status={status} snackClose={() => setOpen(false)}  />
    </div>
  )
}

export default RedeemPoints